import { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken } from 'services/httpService';
import { getLiveStatus, getRunningStatus } from 'store/live/liveThunk';
import { setSnapshotsCount, clearSnapshot, clearLiveDetails } from 'store/live/liveSlice';

import {
  actionTypes,
  overviewIngestGraphs,
  getEncodeGraphs,
  egressGraphs,
  showDetailsStates,
  navLists
} from 'constants/live';
import {
  nsToTime,
  setNumber,
  setNode,
  setBitrates,
  bytesToSize,
  checkIsTM
} from 'utils/utils';
import { parseIngestStatus, getConfigType } from 'utils/liveUtils';

import useToken from 'hooks/useToken';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import CloseButton from 'react-bootstrap/CloseButton';
import Badge from 'components/Badge';
import LiveSubTitle from 'components/LiveSubTitle';
import LiveCopyItems from 'components/LiveCopyItems';
import LiveDetailsNavs from 'components/LiveDetailsNavs';
import LiveDetailsSection from 'components/LiveDetailsSection';
import LiveLogSection from 'components/LiveLogSection';
import LiveConfigDialog from 'components/LiveConfigDialog';
import Loader from 'components/Loader';

const LiveDetailsDialog = ({ selectedActiveId, onCancel }) => {
  const dispatch = useDispatch();
  const token = useToken();

  const {
    groups,
    settings: { theme }
  } = useSelector(({ user }) => user);
  const {
    profile_config,
    live_configs,
    live_details,
    snapshot: {
      index: { current: currentIndex }
    }
  } = useSelector(({ live }) => live);
  const { ingest_url, status } = { ...live_details };

  const config =
    live_configs?.find(({ stack_uuid }) => stack_uuid === selectedActiveId) || {};
  const {
    name,
    stream_status_overview,
    state,
    state_since,
    deployed_at,
    config_uuid,
    type,
    static_config
  } = config;
  const source = static_config?.source;
  const isShowDetailsState = state && showDetailsStates.includes(state);
  const isHLS = type === 'hls';

  const ergress = status?.packager;
  const ingest_status = status?.multi_ingest_status
    ? status.multi_ingest_status
    : [status?.ingest_status];

  const [key, setKey] = useState(navLists[0]);
  const [openName, setOpenName] = useState(null);
  const [lastestUpdate, setLastestUpdate] = useState(0);

  const { checkRunningConfig, checkRunningLogs, checkIngestDetails } = actionTypes;
  const eventKeys = [checkRunningConfig, checkRunningLogs, checkIngestDetails];

  const uploadedBytes = bytesToSize(ergress?.num_uploaded_bytes);

  const handleOpenName = name => {
    setOpenName(name);
  };

  const handleCloseDetails = () => {
    onCancel();
    dispatch(clearLiveDetails());
  };

  useEffect(() => {
    if (!isShowDetailsState) handleCloseDetails();
  }, [state]); // eslint-disable-line

  useEffect(() => {
    if (isHLS) setKey(navLists[0]);
  }, [isHLS]); // eslint-disable-line

  useEffect(() => {
    const imgArray = [];
    const count = source?.multi_video?.length || 1;
    for (let i = 0; i < count; i++) {
      imgArray.push(i);
    }
    dispatch(setSnapshotsCount({ current: 0, all: imgArray }));
    return () => dispatch(clearSnapshot());
  }, [selectedActiveId]); // eslint-disable-line

  useEffect(() => {
    if (!selectedActiveId || !isShowDetailsState) return;
    setOpenName(null);

    const source = CancelToken.source();
    const payload = { ...profile_config, stack_uuid: selectedActiveId, source };
    dispatch(getLiveStatus(payload));

    const fetchRunningStatus = setInterval(() => {
      dispatch(getRunningStatus(payload));
    }, 5000);
    return () => {
      source?.cancel();
      clearInterval(fetchRunningStatus);
    };
  }, [isShowDetailsState, selectedActiveId, profile_config, dispatch]);

  useEffect(() => {
    setLastestUpdate(0);
    const timer = setInterval(() => setLastestUpdate(preState => preState + 1), 1000);
    return () => clearInterval(timer);
  }, [status, currentIndex]); // eslint-disable-line

  const accessUrl = [{ 'Access URL': status?.access_url }];
  const urlContents = ingest_url
    ? [...accessUrl, { 'Source URL': ingest_url }]
    : accessUrl;

  const uuid = [{ 'Config UUID': config_uuid }, { 'Stack UUID': selectedActiveId }];

  const ingestContents = parseIngestStatus({ ingest_status });

  const encodeContents = status?.jobs?.map(({ type: title, queue, nodes_capable }) => ({
    title,
    subtitle: `${queue?.running} / ${setNode(nodes_capable)}`,
    data: [
      { Waiting: setNumber(queue?.waiting) },
      { Done: setNumber(queue?.completed) },
      { Failed: setNumber(queue?.failed) }
    ]
  }));

  const egressContents = [
    {
      title: 'packager',
      data: [
        { Queue: ergress?.packaging_queue_size },
        { Error: ergress?.num_packaging_errors }
      ]
    },
    {
      title: 'upload',
      data: [{ Queue: ergress?.upload_queue_size }, { Error: ergress?.num_output_errors }]
    },
    {
      title: 'track',
      subtitle: '(HH:MM:SS)',
      data: [
        { Video: nsToTime(ergress?.video_bundles?.[0]?.total_packaged_time) },
        { Audio: nsToTime(ergress?.audio_tracks?.[0]?.total_packaged_time) }
      ]
    },
    {
      title: 'average bitrate',
      subtitle: '(Mbps)',
      data: [
        { Current: setBitrates(ergress?.avg_bitrate_lastN) },
        { Lifetime: setBitrates(ergress?.avg_bitrate_lifetime) }
      ]
    },
    {
      title: 'uploaded bytes',
      subtitle: `(${uploadedBytes.unit})`,
      data: [uploadedBytes.size]
    }
  ];

  const overviewContents = isHLS
    ? [
        { eventKey: navLists[1], charts: overviewIngestGraphs },
        {
          eventKey: navLists[3],
          charts: [{ ...egressGraphs[2], height: 196 + 72 }]
        }
      ]
    : [
        { eventKey: navLists[1], charts: overviewIngestGraphs },
        { eventKey: navLists[3], data: egressContents.slice(2, 3) }
      ];

  const statusContents = [
    {
      eventKey: navLists[1],
      carousel: { data: ingestContents },
      data: ingestContents?.[currentIndex]
    },
    { eventKey: navLists[2], data: encodeContents, charts: getEncodeGraphs(isHLS) },
    { eventKey: navLists[3], data: egressContents, charts: egressGraphs }
  ];

  return (
    <>
      {live_details?.loading ? (
        <Loader />
      ) : openName ? (
        eventKeys.map(eventKey => (
          <Fragment key={eventKey}>
            {openName === eventKey && (
              <LiveConfigDialog eventKey={eventKey} onCancel={() => handleOpenName()} />
            )}
          </Fragment>
        ))
      ) : (
        <section
          key={selectedActiveId}
          className='modal-background rounded dp-02 fade-in form-dialog d-flex flex-column position-absolute w-100 p-3'
        >
          <Modal.Header className='position-relative'>
            <CloseButton
              className='position-fixed top-0 end-0 m-3'
              variant={theme === 'dark' ? 'white' : null}
              onClick={handleCloseDetails}
            />
            <Modal.Title className='d-flex align-items-center gap-2'>
              <h1 className='h4 color-high m-0'>
                <span>{name}</span>
              </h1>
              <h6 className='m-0'>
                <Badge.Line>{getConfigType({ type, groups })}</Badge.Line>
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='form-scrollable d-flex flex-column d-grid gap-2 pt-0'>
            <section>
              <LiveCopyItems contents={urlContents} />
            </section>
            <Tab.Container
              activeKey={key}
              onSelect={key => setKey(key)}
              unmountOnExit={true}
            >
              <LiveDetailsNavs
                activeKey={key}
                navItems={navLists}
                streamStatus={stream_status_overview}
                lastestUpdate={lastestUpdate}
              />
              <LiveDetailsSection
                token={token}
                stack_uuid={selectedActiveId}
                state_since={state_since}
                deployed_at={deployed_at}
                streamStatus={stream_status_overview}
                contents={{ overviewContents, statusContents }}
                onClick={() => handleOpenName(checkIngestDetails)}
              />
            </Tab.Container>
            {key === navLists[0] && (
              <>
                <section>
                  <LiveSubTitle
                    title='transcoder_config'
                    button='check_config'
                    onClick={() => handleOpenName(checkRunningConfig)}
                  />
                  <LiveCopyItems contents={uuid} />
                </section>
                <LiveLogSection>
                  <LiveSubTitle
                    title='log_messages'
                    button='check_logs'
                    disabled={!status?.messages}
                    onClick={() => handleOpenName(checkRunningLogs)}
                  />
                </LiveLogSection>
              </>
            )}
          </Modal.Body>
        </section>
      )}
    </>
  );
};

export default LiveDetailsDialog;
